import Head from 'next/head';
import Link from 'next/link';
import { Form, message, Select } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useRouter } from 'next/router';
import { getAllServices } from '../redux/actions/services';
import useTrans from '../hooks/useTrans';

function Layout({ children, webViewMobile, web }) {
  const dispatch = useDispatch();
  const [service, setService] = useState([]);
  const [show, setShow] = useState();
  const [marginTop, setMarginTop] = useState(0);
  const [show_menu_mobile, setShowMenuMobile] = useState();
  const [value_select, setValueSelect] = useState('vi');
  const [show_header, setShowHeader] = useState('');
  const [padding_top, setPaddingTop] = useState('');
  const trans = useTrans();
  const router = useRouter();
  const [y, setY] = useState(0);
  let positionY = React.useRef(null);
  const refClickLanguage = useRef();
  const refClickLanguageMb = useRef();
  useEffect(() => {
    localStorage.setItem('lg', router.locale);
    getListServiceAll();
    setValueSelect(router.locale);

    window.addEventListener('scroll', onScroll);
  }, router.locale);
  const handleClickOut = event => {
    const { target } = event;
    const isMobile = window.innerWidth < 1200;
    if (
      !isMobile &&
      refClickLanguage &&
      !refClickLanguage.current.contains(target)
    ) {
      setShow('');
    }

    if (
      isMobile &&
      refClickLanguageMb &&
      !refClickLanguageMb.current.contains(target)
    ) {
      setShow('');
    }
  };
  useEffect(() => {
    window.addEventListener('click', handleClickOut);
    return () => {
      window.removeEventListener('click', handleClickOut);
    };
  }, []);
  const getListServiceAll = async () => {
    const data = await getAllServices({}, dispatch);
    if (data) {
      setService(data?.rows);
    }
  };
  const displayDrop = async () => {
    if (show === 'show') {
      setShow('');
    } else {
      setShow('show');
    }
  };
  const lockScroll = React.useCallback(() => {
    positionY.current = window.scrollY;
    document.body.style.overflowY = 'hidden';
    document.body.style.position = 'fixed';
    document.body.style.top = `${positionY.current}px`;
  }, []);

  const unlockScroll = React.useCallback(() => {
    document.body.style.overflowY = '';
    document.body.style.position = '';
    document.body.style.top = '';
    scrollTo(0, positionY.current);
  }, []);
  const showMenuApp = (goTop = false) => {
    if (goTop) {
      document.body.style.overflowX = 'auto';
      window.scrollTo(0, 0);
      document.body.style.overflowX = 'hidden';
    }
    if (show_menu_mobile === 'open') {
      setShowMenuMobile('');
      unlockScroll();
    } else {
      setShowMenuMobile('open');
      lockScroll();
    }
  };
  const setLanguageSelect = key => {
    setValueSelect(key);
    setShow('');
    const { pathname, asPath, query } = router;
    router.push({ pathname, query }, asPath, { locale: key });
  };

  const [scrollTop, setScrollTop] = useState(false);

  const onScroll = e => {
    // let window = e.currentTarget;
    if (!window.vinhY) {
      window.vinhY = 0;
    }
    if (window.pageYOffset < 175) {
      setShowHeader('');
      setScrollTop(false);
    } else if (window.vinhY > window.pageYOffset) {
      setShowHeader('sticky');
      setScrollTop(true);
    } else if (window.vinhY < window.pageYOffset) {
      setShowHeader('');
      setScrollTop(false);
    }

    window.vinhY = window.pageYOffset;
  };

  const [checkTop, setCheckTop] = useState();
  useEffect(() => {
    window.addEventListener('scroll', e => {
      if (window.scrollY < 5) {
        setCheckTop('');
      } else if (window.scrollY > 5) {
        setCheckTop('hidden');
      }
    });
  });

  const dataSeo = {
    url: `https://tcom.vn`,
    type: 'website',
    title: 'TCOM - Công ty công nghệ đa lĩnh vực hàng đầu Việt Nam',
    description:
      'Cung cấp dịch vụ offshore cho thị trường Nhật Bản. Tư vấn và phát triển các sản phẩm dựa trên công nghệ Trí tuệ nhân tạo A.I, Blockchain, AR/VR, Livestreaming cho các tổ chức, doanh nghiệp trong nước và quốc tế.',
    domain: 'tcom.vn',
    image: 'https://tcom.vn/assets/images/about/gallery-2.png',
    card: 'summary_large_image',
  };

  return (
    <>
      {/* <MetaSeo {...dataSeo} /> */}
      <Head>
        <meta charSet='UTF-8' />
        <meta httpEquiv='X-UA-Compatible' content='IE=edge' />
        <meta
          name='viewport'
          content='width=device-width, initial-scale=1.0, maximum-scale=1.0, user-scalable=no'
        />
        <title>TCOM Corporation</title>
        <link
          rel='apple-touch-icon'
          sizes='57x57'
          href='/apple-icon-57x57.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='60x60'
          href='/apple-icon-60x60.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='72x72'
          href='/apple-icon-72x72.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='76x76'
          href='/apple-icon-76x76.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='114x114'
          href='/apple-icon-114x114.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='120x120'
          href='/apple-icon-120x120.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='144x144'
          href='/apple-icon-144x144.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='152x152'
          href='/apple-icon-152x152.png'
        />
        <link
          rel='apple-touch-icon'
          sizes='180x180'
          href='/apple-icon-180x180.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='192x192'
          href='/android-icon-192x192.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='32x32'
          href='/favicon-32x32.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='96x96'
          href='/favicon-96x96.png'
        />
        <link
          rel='icon'
          type='image/png'
          sizes='16x16'
          href='/favicon-16x16.png'
        />
        <link rel='manifest' href='/manifest.json' />
        <meta name='msapplication-TileColor' content='#ffffff' />
        <meta name='msapplication-TileImage' content='/ms-icon-144x144.png' />
        <meta name='theme-color' content='#ffffff'></meta>
      </Head>
      <div id={web} className={`${checkTop}`}>
        <div id={`header`} className={`${show_header}`}>
          <div className='header-desktop'>
            <div className='container'>
              <div className='row'>
                <div className='col-auto col-left'>
                  <Link href='/'>
                    <a className='logo'>
                      {scrollTop ? (
                        <img src='/assets/images/logo-black.svg' alt='TCOM' />
                      ) : (
                        <img
                          src={`${
                            web !== 'home' && web !== 'offshore'
                              ? '/assets/images/logo-black.svg'
                              : '/assets/images/logo-white.svg'
                          } `}
                          alt='TCOM'
                        />
                      )}
                    </a>
                  </Link>
                </div>
                <div className='col col-right'>
                  <div className='megamenu'>
                    <ul>
                      <li className={web === 'about' ? 'active' : ''}>
                        <Link href='/about'>
                          <a>{trans.layout.about}</a>
                        </Link>
                      </li>
                      <li className={web === 'service' ? 'active' : ''}>
                        <Link href='/service'>
                          <a>{trans.layout.service}</a>
                        </Link>
                      </li>
                      <li className={web === 'solution' ? 'active' : ''}>
                        <Link href='/solution'>
                          <a>{trans.layout.solution}</a>
                        </Link>
                      </li>
                      <li className={web === 'project' ? 'active' : ''}>
                        <Link href='/project'>
                          <a>{trans.layout.project}</a>
                        </Link>
                      </li>
                      <li>
                        <a href='https://tuyendung.tcom.vn/' target='_blank'>
                          {trans.layout.job}
                        </a>
                      </li>
                      <li className={web === 'news' ? 'active' : ''}>
                        <Link href='/news'>
                          <a>{trans.layout.news}</a>
                        </Link>
                      </li>
                      <li className={web === 'contact' ? 'active' : ''}>
                        <Link href='/contact'>
                          <a>{trans.layout.contact}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                  <div ref={refClickLanguage} className='language'>
                    <div className='dropdown'>
                      <button
                        onClick={() => displayDrop()}
                        data-toggle='dropdown'
                        data-display='static'
                      >
                        {value_select === 'jp' && (
                          <>
                            <img
                              src='/assets/images/flags/japan.png'
                              alt='Japan'
                            />
                            <span>Japan</span>
                          </>
                        )}
                        {value_select === 'en' && (
                          <>
                            <img
                              src='/assets/images/flags/english.png'
                              alt='English'
                            />
                            <span>English</span>
                          </>
                        )}
                        {value_select === 'vi' && (
                          <>
                            <img
                              src='/assets/images/flags/vietnam.png'
                              alt='Viet Nam'
                            />
                            <span>Viet Nam</span>
                          </>
                        )}
                        <svg
                          onClick={() => displayDrop()}
                          width={8}
                          height={5}
                          viewBox='0 0 8 5'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <g clipPath='url(#clip0_401_9445)'>
                            <path
                              d='M7.94398 0.731689L4.12685 4.55302C4.05539 4.62449 3.94188 4.62449 3.87042 4.55302L0.053287 0.731689C-0.0602179 0.618184 0.0196559 0.424805 0.179404 0.424805H7.81786C7.97761 0.424805 8.05749 0.618184 7.94398 0.731689Z'
                              fill='#121212'
                            />
                          </g>
                          <defs>
                            <clipPath id='clip0_401_9445'>
                              <rect
                                width={8}
                                height='4.17867'
                                fill='white'
                                transform='translate(0 0.424805)'
                              />
                            </clipPath>
                          </defs>
                        </svg>
                      </button>

                      <div className={`dropdown-menu ${show}`}>
                        <a
                          onClick={() => setLanguageSelect('vi')}
                          className='dropdown-item'
                        >
                          <img
                            src='/assets/images/flags/vietnam.png'
                            alt='Viet Nam'
                          />
                          Viet Nam
                        </a>
                        <a
                          onClick={() => setLanguageSelect('en')}
                          className='dropdown-item'
                        >
                          <img
                            src='/assets/images/flags/english.png'
                            alt='English'
                          />
                          English
                        </a>
                        <a
                          href='https://tcom-japan.jp/'
                          target='_blank'
                          className='dropdown-item'
                        >
                          <img
                            src='/assets/images/flags/japan.png'
                            alt='Japan'
                          />
                          Japan
                        </a>
                      </div>
                    </div>
                    {/* <select>
                                            <option>
                                                <a className='dropdown-item' href='true'>
                                                    <img src='/assets/images/flags/vietnam.png' alt='Viet Nam' />
                                                    Viet Nam
                                                </a>
                                            </option>
                                            <option>
                                                <a className='dropdown-item' href='true'>
                                                    <img src='/assets/images/flags/english.png' alt='English' />
                                                    English
                                                </a>
                                            </option>
                                            <option> <a className='dropdown-item' href='https://tcom-japan.jp/' target='_blank'>
                                                <img src='/assets/images/flags/japan.png' alt='Japan' />
                                                Japan
                                            </a></option>
                                        </select> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className='header-mobile'>
            <div className='container'>
              <div className='row'>
                <div className='col'>
                  <Link href='/'>
                    <a>
                      {scrollTop ? (
                        <img
                          style={{ width: 75 }}
                          src='/assets/images/logo-header-mobile-2.svg'
                          alt='TCOM'
                        />
                      ) : (
                        <img
                          style={{ width: 75 }}
                          src={`${
                            web !== 'home' && web !== 'offshore'
                              ? '/assets/images/logo-header-mobile-2.svg'
                              : '/assets/images/logo-header-mobile.svg'
                          } `}
                          alt='TCOM'
                        />
                      )}
                      {/* {web === 'home' || web === 'offshore' ? (
                        <img
                          src="/assets/images/logo-header-mobile.svg"
                          alt="TCOM"
                        />
                      ) : (
                        <img
                          style={{ width: 75 }}
                          src="/assets/images/logo-header-mobile-2.svg"
                          alt="TCOM"
                        />
                      )} */}
                    </a>
                  </Link>
                </div>
                <div className='col-auto'>
                  <a onClick={() => showMenuApp()} className='js-open-canvas'>
                    <svg
                      width={35}
                      height={24}
                      viewBox='0 0 35 24'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M0.947754 2.00004C0.947754 1.55801 1.12335 1.13409 1.43591 0.82153C1.74847 0.508969 2.17239 0.333374 2.61442 0.333374H32.6144C33.0564 0.333374 33.4804 0.508969 33.7929 0.82153C34.1055 1.13409 34.2811 1.55801 34.2811 2.00004C34.2811 2.44207 34.1055 2.86599 33.7929 3.17855C33.4804 3.49111 33.0564 3.66671 32.6144 3.66671H2.61442C2.17239 3.66671 1.74847 3.49111 1.43591 3.17855C1.12335 2.86599 0.947754 2.44207 0.947754 2.00004Z'
                        fill='white'
                      />
                      <path
                        d='M0.947754 12.0533C0.947754 11.6112 1.12335 11.1873 1.43591 10.8748C1.74847 10.5622 2.17239 10.3866 2.61442 10.3866H32.6144C33.0564 10.3866 33.4804 10.5622 33.7929 10.8748C34.1055 11.1873 34.2811 11.6112 34.2811 12.0533C34.2811 12.4953 34.1055 12.9192 33.7929 13.2318C33.4804 13.5443 33.0564 13.7199 32.6144 13.7199H2.61442C2.17239 13.7199 1.74847 13.5443 1.43591 13.2318C1.12335 12.9192 0.947754 12.4953 0.947754 12.0533Z'
                        fill='white'
                      />
                      <path
                        d='M2.61442 20.4399C2.17239 20.4399 1.74847 20.6155 1.43591 20.9281C1.12335 21.2407 0.947754 21.6646 0.947754 22.1066C0.947754 22.5486 1.12335 22.9726 1.43591 23.2851C1.74847 23.5977 2.17239 23.7733 2.61442 23.7733H32.6144C33.0564 23.7733 33.4804 23.5977 33.7929 23.2851C34.1055 22.9726 34.2811 22.5486 34.2811 22.1066C34.2811 21.6646 34.1055 21.2407 33.7929 20.9281C33.4804 20.6155 33.0564 20.4399 32.6144 20.4399H2.61442Z'
                        fill='white'
                      />
                    </svg>
                  </a>
                  <div className={`canvas-menu ${show_menu_mobile}`}>
                    <div className='top-menu'>
                      <Link href='/'>
                        <a>
                          <img
                            src='/assets/images/logo-canvas-menu.svg'
                            alt='TCOM'
                          />
                        </a>
                      </Link>
                      <a
                        onClick={() => showMenuApp()}
                        className='js-close-canvas'
                      >
                        <svg
                          width={12}
                          height={12}
                          viewBox='0 0 12 12'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M0.968875 0.861454C1.03854 0.791609 1.12131 0.736194 1.21242 0.698385C1.30354 0.660575 1.40122 0.641113 1.49988 0.641113C1.59853 0.641113 1.69621 0.660575 1.78733 0.698385C1.87844 0.736194 1.96121 0.791609 2.03088 0.861454L5.99987 4.83195L9.96887 0.861454C10.0386 0.791722 10.1214 0.736407 10.2125 0.698668C10.3036 0.66093 10.4013 0.641506 10.4999 0.641506C10.5985 0.641506 10.6961 0.66093 10.7872 0.698668C10.8784 0.736407 10.9611 0.791722 11.0309 0.861454C11.1006 0.931185 11.1559 1.01397 11.1937 1.10508C11.2314 1.19619 11.2508 1.29384 11.2508 1.39245C11.2508 1.49107 11.2314 1.58872 11.1937 1.67983C11.1559 1.77094 11.1006 1.85372 11.0309 1.92345L7.06037 5.89245L11.0309 9.86145C11.1006 9.93118 11.1559 10.014 11.1937 10.1051C11.2314 10.1962 11.2508 10.2938 11.2508 10.3925C11.2508 10.4911 11.2314 10.5887 11.1937 10.6798C11.1559 10.7709 11.1006 10.8537 11.0309 10.9235C10.9611 10.9932 10.8784 11.0485 10.7872 11.0862C10.6961 11.124 10.5985 11.1434 10.4999 11.1434C10.4013 11.1434 10.3036 11.124 10.2125 11.0862C10.1214 11.0485 10.0386 10.9932 9.96887 10.9235L5.99987 6.95295L2.03088 10.9235C1.96114 10.9932 1.87836 11.0485 1.78725 11.0862C1.69614 11.124 1.59849 11.1434 1.49988 11.1434C1.40126 11.1434 1.30361 11.124 1.2125 11.0862C1.12139 11.0485 1.03861 10.9932 0.968875 10.9235C0.899143 10.8537 0.843829 10.7709 0.80609 10.6798C0.768352 10.5887 0.748928 10.4911 0.748928 10.3925C0.748928 10.2938 0.768352 10.1962 0.80609 10.1051C0.843829 10.014 0.899143 9.93118 0.968875 9.86145L4.93938 5.89245L0.968875 1.92345C0.899031 1.85379 0.843616 1.77102 0.805806 1.6799C0.767997 1.58879 0.748535 1.4911 0.748535 1.39245C0.748535 1.2938 0.767997 1.19612 0.805806 1.105C0.843616 1.01389 0.899031 0.931122 0.968875 0.861454Z'
                            fill='#525252'
                          />
                        </svg>
                      </a>
                    </div>
                    <div className='megamenu'>
                      <ul>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'home' ? 'active' : ''}
                        >
                          <Link href='/'>
                            <a>{trans.layout.home}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'about' ? 'active' : ''}
                        >
                          <Link href='/about'>
                            <a>{trans.layout.introduce}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'service' ? 'active' : ''}
                        >
                          <Link href='/service'>
                            <a>{trans.layout.service}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'solution' ? 'active' : ''}
                        >
                          <Link href='/solution'>
                            <a>{trans.layout.solution}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'project' ? 'active' : ''}
                        >
                          <Link href='/project'>
                            <a>{trans.layout.project}</a>
                          </Link>
                        </li>
                        <li onClick={() => showMenuApp(true)}>
                          <Link href='https://tuyendung.tcom.vn/'>
                            <a>{trans.layout.job}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'news' ? 'active' : ''}
                        >
                          <Link href='/news'>
                            <a>{trans.layout.news}</a>
                          </Link>
                        </li>
                        <li
                          onClick={() => showMenuApp(true)}
                          className={web === 'contact' ? 'active' : ''}
                        >
                          <Link href='/contact'>
                            <a>{trans.layout.contact}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                    <div ref={refClickLanguageMb} className='language'>
                      <div className='dropdown'>
                        <button
                          onClick={() => displayDrop()}
                          data-toggle='dropdown'
                          data-display='static'
                        >
                          {value_select === 'jp' && (
                            <>
                              <img
                                src='/assets/images/flags/japan.png'
                                alt='Japan'
                              />
                              <span>Japan</span>
                            </>
                          )}
                          {value_select === 'en' && (
                            <>
                              <img
                                src='/assets/images/flags/english.png'
                                alt='English'
                              />
                              <span>English</span>
                            </>
                          )}
                          {value_select === 'vi' && (
                            <>
                              <img
                                src='/assets/images/flags/vietnam.png'
                                alt='Viet Nam'
                              />
                              <span>Viet Nam</span>
                            </>
                          )}
                          <svg
                            onClick={() => displayDrop()}
                            width={8}
                            height={5}
                            viewBox='0 0 8 5'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <g clipPath='url(#clip0_401_9445)'>
                              <path
                                d='M7.94398 0.731689L4.12685 4.55302C4.05539 4.62449 3.94188 4.62449 3.87042 4.55302L0.053287 0.731689C-0.0602179 0.618184 0.0196559 0.424805 0.179404 0.424805H7.81786C7.97761 0.424805 8.05749 0.618184 7.94398 0.731689Z'
                                fill='#121212'
                              />
                            </g>
                            <defs>
                              <clipPath id='clip0_401_9445'>
                                <rect
                                  width={8}
                                  height='4.17867'
                                  fill='white'
                                  transform='translate(0 0.424805)'
                                />
                              </clipPath>
                            </defs>
                          </svg>
                        </button>

                        <div className={`dropdown-menu ${show}`}>
                          <a
                            onClick={() => setLanguageSelect('vi')}
                            className='dropdown-item'
                          >
                            <img
                              src='/assets/images/flags/vietnam.png'
                              alt='Viet Nam'
                            />
                            Viet Nam
                          </a>
                          <a
                            onClick={() => setLanguageSelect('en')}
                            className='dropdown-item'
                          >
                            <img
                              src='/assets/images/flags/english.png'
                              alt='English'
                            />
                            English
                          </a>
                          <a
                            href='https://tcom-japan.com/'
                            target='_blank'
                            className='dropdown-item'
                          >
                            <img
                              src='/assets/images/flags/japan.png'
                              alt='Japan'
                            />
                            Japan
                          </a>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div>{children}</div>
        {router.pathname.includes('/contact') ? null : (
          <div className='r-contact'>
            <div className='container'>
              <div className='contact-box'>
                <div className='title-left'>
                  <h3 style={{ width: '300px' }}>{trans.layout.contact}</h3>
                  <p className='desc'>{trans.layout.contact_description}</p>
                </div>
                <Link
                  href='/contact'
                  onClick={() => {
                    console.log('click~');
                  }}
                >
                  <a className='btn-contact'>
                    <svg
                      width={25}
                      height={25}
                      viewBox='0 0 25 25'
                      fill='none'
                      xmlns='http://www.w3.org/2000/svg'
                    >
                      <path
                        d='M21.9926 4.13984C21.9017 4.13047 21.8101 4.13047 21.7192 4.13984H3.05255C2.93291 4.14168 2.81407 4.15962 2.69922 4.19317L12.3326 13.7865L21.9926 4.13984Z'
                        fill='white'
                      />
                      <path
                        d='M22.9799 5.06738L13.2732 14.734C13.0234 14.9824 12.6855 15.1218 12.3332 15.1218C11.981 15.1218 11.643 14.9824 11.3932 14.734L1.77323 5.14072C1.74366 5.24941 1.72797 5.36141 1.72656 5.47405V18.8074C1.72656 19.161 1.86704 19.5001 2.11709 19.7502C2.36714 20.0002 2.70627 20.1407 3.0599 20.1407H21.7266C22.0802 20.1407 22.4193 20.0002 22.6694 19.7502C22.9194 19.5001 23.0599 19.161 23.0599 18.8074V5.47405C23.0546 5.33515 23.0276 5.19794 22.9799 5.06738ZM3.97323 18.8074H3.04656V17.854L7.89323 13.0474L8.83323 13.9874L3.97323 18.8074ZM21.7132 18.8074H20.7799L15.9199 13.9874L16.8599 13.0474L21.7066 17.854L21.7132 18.8074Z'
                        fill='white'
                      />
                    </svg>
                    {trans.layout.contact_submit}
                  </a>
                </Link>
              </div>
            </div>
          </div>
        )}

        {/* end footer contact */}
        {/* footer */}
        <div id='footer'>
          {/* footer main */}
          <div className='container'>
            <div className='main'>
              <div className='row'>
                <div className='col-12 col-xl-4'>
                  <a href='#' className='logo'>
                    <img src='/assets/images/logo-footer.svg' alt='TCOM' />
                  </a>
                  <h3
                    className='title corporation'
                    style={{ marginBottom: '60px' }}
                  >
                    TCOM Corporation
                  </h3>
                  <div className='iconLogo'>
                    <ul>
                      <li>
                        <a
                          href='https://www.facebook.com/TCOM.Corporation'
                          target={'_blank'}
                        >
                          <svg
                            width='33'
                            height='33'
                            viewBox='0 0 33 33'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <circle cx='16.5' cy='16.5' r='16.5' fill='white' />
                            <path
                              d='M20.1116 10.2951H21.9233V7.13974C21.6107 7.09674 20.5358 7 19.2839 7C16.6718 7 14.8825 8.643 14.8825 11.6627V14.4419H12V17.9693H14.8825L14.8825 26.845H18.4165V17.9701H21.1824L21.6215 14.4427H18.4157V12.0125C18.4165 10.993 18.6911 10.2951 20.1116 10.2951Z'
                              fill='#0063AA'
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.linkedin.com/company/tcom-corporation'
                          target={'_blank'}
                        >
                          <svg
                            width='33'
                            height='33'
                            viewBox='0 0 33 33'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <path
                              d='M16.4999 0C25.6131 0 33 7.38787 33 16.5001C33 25.6122 25.6131 33 16.4999 33C7.38674 33 0 25.6121 0 16.5001C0 7.38798 7.38685 0 16.4999 0Z'
                              fill='white'
                            />
                            <path
                              d='M9.29779 22.671H12.3874V12.3729H9.29779V22.671ZM21.3346 12.0162C19.8351 12.0162 18.4935 12.5637 17.5417 13.7724V12.339H14.4407V22.6711H17.5417V17.0837C17.5417 15.9029 18.6235 14.751 19.9785 14.751C21.3336 14.751 21.6677 15.9029 21.6677 17.0549V22.67H24.7574V16.8249C24.7573 12.7648 22.8351 12.0162 21.3346 12.0162ZM10.8282 11.3437C11.6821 11.3437 12.3751 10.6507 12.3751 9.79681C12.3751 8.94292 11.6821 8.25 10.8282 8.25C9.97428 8.25 9.28125 8.94303 9.28125 9.79692C9.28125 10.6508 9.97428 11.3437 10.8282 11.3437Z'
                              fill='#0063AA'
                            />
                          </svg>
                        </a>
                      </li>
                      <li>
                        <a
                          href='https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw'
                          target={'_blank'}
                        >
                          <svg
                            width='33'
                            height='33'
                            viewBox='0 0 33 33'
                            fill='none'
                            xmlns='http://www.w3.org/2000/svg'
                          >
                            <circle cx='16.5' cy='16.5' r='16.5' fill='white' />
                            <path
                              d='M24.6452 12.8745C24.5484 12.5115 24.3587 12.1805 24.095 11.9146C23.8313 11.6487 23.5028 11.4572 23.1425 11.3592C21.8163 11 16.5 11 16.5 11C16.5 11 11.1837 11 9.85748 11.3573C9.49703 11.4549 9.16842 11.6463 8.90465 11.9123C8.64089 12.1783 8.45126 12.5095 8.3548 12.8726C8 14.2102 8 17 8 17C8 17 8 19.7898 8.3548 21.1255C8.55022 21.8631 9.12701 22.4439 9.85748 22.6408C11.1837 23 16.5 23 16.5 23C16.5 23 21.8163 23 23.1425 22.6408C23.8749 22.4439 24.4498 21.8631 24.6452 21.1255C25 19.7898 25 17 25 17C25 17 25 14.2102 24.6452 12.8745ZM14.8114 19.5605V14.4395L19.2132 16.9809L14.8114 19.5605Z'
                              fill='#0063AA'
                            />
                          </svg>
                        </a>
                      </li>
                    </ul>
                  </div>
                  <div className='block-address update_contact_block_all'>
                    <h3 className='title'> {trans.contact_a}</h3>
                    <ul>
                      <li>
                        <div className='update_contact_block'>
                          <a
                            className='update_contact_a'
                            href='tel:+84 24 3910 2030'
                          >
                            <img src='/assets/images/home/phone_u.svg' alt='' />
                            <span className=''>+84 24 3910 2030</span>
                          </a>
                        </div>
                      </li>
                      <li>
                        <a
                          className='update_contact_block'
                          href='mailto:info@tcom.vn'
                        >
                          <div className='update_contact_a'>
                            <img src='/assets/images/home/mail_u.svg' alt='' />
                            <span className=''>info@tcom.vn</span>
                          </div>
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-xl-3'>
                  <div className='customMarginTop '>
                    <div className='block-address'>
                      <h3 className='title'>
                        {trans.layout.footer_title}
                        <svg
                          width={31}
                          height={21}
                          viewBox='0 0 31 21'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M11.9625 0.140625H0.441406V20.2121H11.9625H30.4414V0.140625H11.9625Z'
                            fill='#D81F2A'
                          />
                          <path
                            d='M15.4373 4.36914L16.7683 8.4729H21.0642L17.5883 11.0079L18.9133 15.1117L15.4373 12.5767L11.9614 15.1117L13.2924 11.0079L9.81641 8.4729H14.1123L15.4373 4.36914Z'
                            fill='#FEDA46'
                          />
                        </svg>
                      </h3>
                      <ul>
                        <li>
                          <div className='icon'>
                            <div
                              style={{ color: 'white', display: 'inline-flex' }}
                              className='customMarginBottom'
                            >
                              <svg
                                width='25'
                                height='25'
                                viewBox='0 0 25 25'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <g opacity='0.5'>
                                  <path
                                    d='M12 13C13.6569 13 15 11.6569 15 10C15 8.34315 13.6569 7 12 7C10.3431 7 9 8.34315 9 10C9 11.6569 10.3431 13 12 13Z'
                                    stroke='white'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                  />
                                  <path
                                    d='M12 2C9.87827 2 7.84344 2.84285 6.34315 4.34315C4.84285 5.84344 4 7.87827 4 10C4 11.892 4.402 13.13 5.5 14.5L12 22L18.5 14.5C19.598 13.13 20 11.892 20 10C20 7.87827 19.1571 5.84344 17.6569 4.34315C16.1566 2.84285 14.1217 2 12 2V2Z'
                                    stroke='white'
                                    stroke-width='2'
                                    stroke-linecap='round'
                                    stroke-linejoin='round'
                                  />
                                  <path
                                    d='M17.4755 12.8455L17 11.382L16.5245 12.8455L15.5142 15.9549H12.2447H10.7059L11.9508 16.8594L14.5959 18.7812L13.5855 21.8906L13.11 23.3541L14.355 22.4496L17 20.5279L19.645 22.4496L20.89 23.3541L20.4145 21.8906L19.4041 18.7812L22.0492 16.8594L23.2941 15.9549H21.7553H18.4858L17.4755 12.8455Z'
                                    fill='white'
                                    stroke='#0063AA'
                                  />
                                </g>
                              </svg>
                              <span>{trans.layout.office_head}</span>
                            </div>
                          </div>
                          <div>
                            <div className='content-margin customMR-42'>
                              {trans.layout.office_head_des}
                            </div>
                          </div>
                        </li>
                      </ul>
                    </div>
                    <div className='block-address TcomJapanPC'>
                      <h3 className='title'>
                        TCOM JAPAN
                        <svg
                          width={31}
                          height={22}
                          viewBox='0 0 31 22'
                          fill='none'
                          xmlns='http://www.w3.org/2000/svg'
                        >
                          <path
                            d='M30.4414 0.140625H0.441406V21.1332H30.4414V0.140625Z'
                            fill='#F1F0F0'
                          />
                          <path
                            d='M15.4377 4.46094C18.8383 4.46094 21.5903 7.22357 21.5903 10.6373C21.5903 14.051 18.8383 16.8136 15.4377 16.8136C12.0372 16.8136 9.28516 14.051 9.28516 10.6373C9.28516 7.22357 12.0372 4.46094 15.4377 4.46094Z'
                            fill='#D61F30'
                          />
                        </svg>
                      </h3>
                      <ul>
                        <li>
                          <div className='icon'>
                            <div className='text-width customTokyo'>
                              <svg
                                style={{ marginRight: 11 }}
                                width={25}
                                height={25}
                                viewBox='0 0 25 25'
                                fill='none'
                                xmlns='http://www.w3.org/2000/svg'
                              >
                                <g opacity='0.5'>
                                  <path
                                    d='M12.4414 13.1406C14.0983 13.1406 15.4414 11.7975 15.4414 10.1406C15.4414 8.48377 14.0983 7.14062 12.4414 7.14062C10.7846 7.14062 9.44141 8.48377 9.44141 10.1406C9.44141 11.7975 10.7846 13.1406 12.4414 13.1406Z'
                                    stroke='white'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                  <path
                                    d='M12.4414 2.14062C10.3197 2.14063 8.28484 2.98348 6.78455 4.48377C5.28426 5.98406 4.44141 8.01889 4.44141 10.1406C4.44141 12.0326 4.84341 13.2706 5.94141 14.6406L12.4414 22.1406L18.9414 14.6406C20.0394 13.2706 20.4414 12.0326 20.4414 10.1406C20.4414 8.01889 19.5986 5.98406 18.0983 4.48377C16.598 2.98348 14.5631 2.14063 12.4414 2.14062V2.14062Z'
                                    stroke='white'
                                    strokeWidth={2}
                                    strokeLinecap='round'
                                    strokeLinejoin='round'
                                  />
                                </g>
                              </svg>
                              <span className='text-white'>
                                {trans.layout.office_Tokyo}
                              </span>
                            </div>
                          </div>
                          <div
                            className='mLeft'
                            style={{ whiteSpace: 'nowrap' }}
                          >
                            {trans.layout.office_Tokyo_des}{' '}
                          </div>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className='col-12 col-xl-3 col-service'>
                  <div className='customMarginTop'>
                    <ul className='customPaddingUl'>
                      <li>
                        <div className='icon'>
                          <div
                            style={{ color: 'white', display: 'inline-flex' }}
                            className='customMarginBottom'
                          >
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M4.5 3V19.5H13.5V3H4.5ZM3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z'
                                  fill='white'
                                />
                                <path
                                  d='M6 6H12V7.5H6V6ZM6 10.5H12V12H6V10.5ZM6 15H12V16.5H6V15ZM15 12H18V13.5H15V12ZM15 15H18V16.5H15V15ZM1.5 19.5H22.5V21H1.5V19.5Z'
                                  fill='white'
                                />
                                <path
                                  d='M15 9V19.5H19.5V9H15ZM14.25 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21H14.25C14.0511 21 13.8603 20.921 13.7197 20.7803C13.579 20.6397 13.5 20.4489 13.5 20.25V8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                            <span>{trans.layout.office_HN}</span>
                          </div>
                        </div>
                        <div className='content-margin customRight'>
                          {trans.layout.office_HN_des}
                        </div>
                      </li>
                    </ul>
                  </div>
                  {/* <h3 className='title'>{trans.layout.footer_service}</h3>
                                    <ul className='link-list'>
                                        {service.map((item, index) => {
                                            return (
                                                <li key={index}><a href={`/${router.locale}/service/${item?.seo_url}`}>{item?.title}</a></li>
                                            );
                                        })}

                                        <li><a href={`/offshore`}>{'Offshore'}</a></li>
                                    </ul> */}
                  {/* <div className='social d-none d-md-block'>
                                        <h3 className='title'>{trans.text_button.keep_in_touch}</h3>
                                        <div className='social-link'>
                                            <ul>
                                                <li className='facebook'>
                                                    <a href='https://www.facebook.com/TCOM.Corporation' target='_blank'>
                                                        <svg width={34} height={34} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                            <circle cx='16.9414' cy='16.6406' r='16.5' fill='white' />
                                                            <path d='M20.553 10.4357H22.3647V7.28037C22.0522 7.23737 20.9772 7.14062 19.7253 7.14062C17.1132 7.14062 15.3239 8.78362 15.3239 11.8034V14.5825H12.4414V18.1099H15.3239L15.3239 26.9856H18.8579V18.1108H21.6238L22.0629 14.5833H18.8571V12.1531C18.8579 11.1336 19.1325 10.4357 20.553 10.4357Z' fill='#0063AA' />
                                                        </svg>
                                                    </a>
                                                </li>

                                                <li className='linkedin'>
                                                    <a href='https://www.linkedin.com/company/tcom-corporation' target='_blank'>

                                                        <svg width={33} height={33} viewBox='0 0 34 34' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                            <g clipPath='url(#clip0_400_1430)'>
                                                                <path d='M16.9413 0.140625C26.0546 0.140625 33.4414 7.5285 33.4414 16.6407C33.4414 25.7529 26.0546 33.1406 16.9413 33.1406C7.82814 33.1406 0.441406 25.7528 0.441406 16.6407C0.441406 7.52861 7.82826 0.140625 16.9413 0.140625Z' fill='white' />
                                                                <path d='M9.7392 22.8116H12.8288V12.5135H9.7392V22.8116ZM21.776 12.1568C20.2765 12.1568 18.9349 12.7044 17.9831 13.913V12.4796H14.8821V22.8117H17.9831V17.2244C17.9831 16.0436 19.0649 14.8916 20.4199 14.8916C21.775 14.8916 22.1091 16.0436 22.1091 17.1955V22.8107H25.1988V16.9655C25.1987 12.9054 23.2765 12.1568 21.776 12.1568ZM11.2696 11.4844C12.1235 11.4844 12.8165 10.7913 12.8165 9.93743C12.8165 9.08355 12.1235 8.39062 11.2696 8.39062C10.4157 8.39062 9.72266 9.08366 9.72266 9.93755C9.72266 10.7914 10.4157 11.4844 11.2696 11.4844Z' fill='#0063AA' />
                                                            </g>
                                                            <defs>
                                                                <clipPath id='clip0_400_1430'>
                                                                    <rect width={33} height={33} fill='white' transform='translate(0.441406 0.140625)' />
                                                                </clipPath>
                                                            </defs>
                                                        </svg>
                                                    </a>
                                                </li>

                                                <li className='youtube'>
                                                    <a href='https://www.youtube.com/channel/UCmeadb8H2pD_05cFz0qvDVw' target='_plank'>
                                                    <svg width='33' height='33' viewBox='0 0 21 21' fill='none' xmlns='http://www.w3.org/2000/svg'>
                                                    <circle cx='10.1774' cy='10.1774' r='10.1774' fill='white'/>
                                                    <path d='M14.992 7.8321C14.9373 7.62687 14.83 7.4397 14.6809 7.28934C14.5318 7.13898 14.3461 7.0307 14.1424 6.97533C13.3925 6.77222 10.3866 6.77222 10.3866 6.77222C10.3866 6.77222 7.38069 6.77222 6.63082 6.97425C6.42701 7.02944 6.24121 7.13766 6.09207 7.28805C5.94294 7.43844 5.83571 7.6257 5.78118 7.83102C5.58057 8.58731 5.58057 10.1647 5.58057 10.1647C5.58057 10.1647 5.58057 11.7421 5.78118 12.4973C5.89167 12.9144 6.2178 13.2428 6.63082 13.3541C7.38069 13.5572 10.3866 13.5572 10.3866 13.5572C10.3866 13.5572 13.3925 13.5572 14.1424 13.3541C14.5565 13.2428 14.8815 12.9144 14.992 12.4973C15.1926 11.7421 15.1926 10.1647 15.1926 10.1647C15.1926 10.1647 15.1926 8.58731 14.992 7.8321ZM9.43184 11.6125V8.71696L11.9207 10.1539L9.43184 11.6125Z' fill='#0063AA'/>
                                                    </svg>
                                                    </a>
                                                </li>
                                            </ul>
                                        </div>
                                    </div> */}
                </div>
                <div className='col-12 col-xl-2'>
                  <div className='customMarginTop pt5 customMT86'>
                    <ul className='customPaddingUl'>
                      <li>
                        <div className='icon'>
                          <div
                            style={{ color: 'white', display: 'inline-flex' }}
                            className='customMarginBottom'
                          >
                            <svg
                              width='24'
                              height='24'
                              viewBox='0 0 24 24'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M4.5 3V19.5H13.5V3H4.5ZM3.75 1.5H14.25C14.4489 1.5 14.6397 1.57902 14.7803 1.71967C14.921 1.86032 15 2.05109 15 2.25V20.25C15 20.4489 14.921 20.6397 14.7803 20.7803C14.6397 20.921 14.4489 21 14.25 21H3.75C3.55109 21 3.36032 20.921 3.21967 20.7803C3.07902 20.6397 3 20.4489 3 20.25V2.25C3 2.05109 3.07902 1.86032 3.21967 1.71967C3.36032 1.57902 3.55109 1.5 3.75 1.5Z'
                                  fill='white'
                                />
                                <path
                                  d='M6 6H12V7.5H6V6ZM6 10.5H12V12H6V10.5ZM6 15H12V16.5H6V15ZM15 12H18V13.5H15V12ZM15 15H18V16.5H15V15ZM1.5 19.5H22.5V21H1.5V19.5Z'
                                  fill='white'
                                />
                                <path
                                  d='M15 9V19.5H19.5V9H15ZM14.25 7.5H20.25C20.4489 7.5 20.6397 7.57902 20.7803 7.71967C20.921 7.86032 21 8.05109 21 8.25V20.25C21 20.4489 20.921 20.6397 20.7803 20.7803C20.6397 20.921 20.4489 21 20.25 21H14.25C14.0511 21 13.8603 20.921 13.7197 20.7803C13.579 20.6397 13.5 20.4489 13.5 20.25V8.25C13.5 8.05109 13.579 7.86032 13.7197 7.71967C13.8603 7.57902 14.0511 7.5 14.25 7.5Z'
                                  fill='white'
                                />
                              </g>
                            </svg>
                            <span style={{ whiteSpace: 'nowrap' }}>
                              {trans.layout.office_HCM}
                            </span>
                          </div>
                        </div>
                        <div className='content-margin'>
                          {trans.layout.office_HCM_des}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-xl-4 tcomJapanMB d-none'>
                  <div className='block-address'>
                    <h3 className='title'>
                      TCOM JAPAN
                      <svg
                        width={31}
                        height={22}
                        viewBox='0 0 31 22'
                        fill='none'
                        xmlns='http://www.w3.org/2000/svg'
                      >
                        <path
                          d='M30.4414 0.140625H0.441406V21.1332H30.4414V0.140625Z'
                          fill='#F1F0F0'
                        />
                        <path
                          d='M15.4377 4.46094C18.8383 4.46094 21.5903 7.22357 21.5903 10.6373C21.5903 14.051 18.8383 16.8136 15.4377 16.8136C12.0372 16.8136 9.28516 14.051 9.28516 10.6373C9.28516 7.22357 12.0372 4.46094 15.4377 4.46094Z'
                          fill='#D61F30'
                        />
                      </svg>
                    </h3>
                    <ul>
                      <li>
                        <div className='icon'>
                          <div className='text-width customTokyo'>
                            <svg
                              style={{ marginRight: 11 }}
                              width={25}
                              height={25}
                              viewBox='0 0 25 25'
                              fill='none'
                              xmlns='http://www.w3.org/2000/svg'
                            >
                              <g opacity='0.5'>
                                <path
                                  d='M12.4414 13.1406C14.0983 13.1406 15.4414 11.7975 15.4414 10.1406C15.4414 8.48377 14.0983 7.14062 12.4414 7.14062C10.7846 7.14062 9.44141 8.48377 9.44141 10.1406C9.44141 11.7975 10.7846 13.1406 12.4414 13.1406Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                                <path
                                  d='M12.4414 2.14062C10.3197 2.14063 8.28484 2.98348 6.78455 4.48377C5.28426 5.98406 4.44141 8.01889 4.44141 10.1406C4.44141 12.0326 4.84341 13.2706 5.94141 14.6406L12.4414 22.1406L18.9414 14.6406C20.0394 13.2706 20.4414 12.0326 20.4414 10.1406C20.4414 8.01889 19.5986 5.98406 18.0983 4.48377C16.598 2.98348 14.5631 2.14063 12.4414 2.14062V2.14062Z'
                                  stroke='white'
                                  strokeWidth={2}
                                  strokeLinecap='round'
                                  strokeLinejoin='round'
                                />
                              </g>
                            </svg>
                            <span className='text-white'>Trụ sở Tokyo</span>
                          </div>
                        </div>
                        <div
                          className='customFz13'
                          style={{ whiteSpace: 'nowrap' }}
                        >
                          〒190-0023 東京都立川市柴崎町3-8-5{' '}
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className='row customRowFooter2 pt-5'>
                <div className='col-12 col-xl-4'>
                  <div className='info text-white'>
                    <h3 className='title'> {trans.layout.footer_introduce}</h3>
                  </div>
                  <div className='content'>
                    <ul>
                      <li>
                        <Link href='/about'>
                          <a>{trans.layout.about}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href='/project'>
                          <a>{trans.layout.project}</a>
                        </Link>
                      </li>
                      <li>
                        <Link href='/news'>
                          <a>{trans.layout.news}</a>
                        </Link>
                      </li>
                      <li>
                        <a href='https://tuyendung.tcom.vn/' target={'_blank'}>
                          {trans.layout.job}
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-xl-3 customService'>
                  <div className='service text-white'>
                    <h3 className='title'>{trans.layout.footer_service}</h3>
                  </div>
                  <div className='content'>
                    <ul>
                      {service.map((item, index) => {
                        if (index % 3 === 0)
                          return (
                            <li key={index}>
                              <Link
                                href={`/${router.locale}/service/${item?.seo_url}`}
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                      <li>
                        <Link href={`/offshore`}>
                          <a>{'Offshore'}</a>
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-xl-3'>
                  <div className='content pt-5 customContent'>
                    <ul>
                      {service.map((item, index) => {
                        if (index % 3 === 1)
                          return (
                            <li key={index}>
                              <Link
                                href={`/${router.locale}/service/${item?.seo_url}`}
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
                <div className='col-12 col-xl-2'>
                  <div className='content pt-5 customContent'>
                    <ul>
                      {service.map((item, index) => {
                        if (index % 3 === 2)
                          return (
                            <li key={index}>
                              <Link
                                href={`/${router.locale}/service/${item?.seo_url}`}
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
              <div className='customRowFooter2-MB d-none pt-5'>
                <div class='row'>
                  <div className='col-8 customService'>
                    <div className='service text-white'>
                      <h3 className='title'> CÁC DỊCH VỤ</h3>
                    </div>
                    <div className='content'>
                      <ul>
                        {service.map((item, index) => {
                          return (
                            <li key={index}>
                              <Link
                                href={`/${router.locale}/service/${item?.seo_url}`}
                              >
                                <a>{item?.title}</a>
                              </Link>
                            </li>
                          );
                        })}
                        <li>
                          <Link href={`/offshore`}>
                            <a>{'Offshore'}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div className='col-4 customService menuFooterMB'>
                    <div className='mainMenuFooter text-white'>
                      <h3 className='title'> MENU</h3>
                    </div>
                    <div class='content' style={{ marginTop: '-3px' }}>
                      <ul>
                        <li>
                          <Link href='/'>
                            <a>{trans.layout.home}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/about'>
                            <a>{trans.layout.introduce}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/service'>
                            <a>{trans.layout.service}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/project'>
                            <a>{trans.layout.project}</a>
                          </Link>
                        </li>
                        <li>
                          <Link href='/contact'>
                            <a>{trans.layout.contact}</a>
                          </Link>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className='rules'>
                                    <p>
                                        <a className='text-white' style={{ textDecoration: "underline", fontSize: 16 }} href='http://'>Điều khoản dịch vụ</a>
                                    </p>
                                    <p>
                                        <a className='text-white' style={{ textDecoration: "underline", fontSize: 16 }} href='http://'>Chính sách bảo mật</a>
                                    </p>
                                </div> */}
              </div>
            </div>
          </div>
          {/* end footer main */}
          {/* copyright */}
          <div className='copyright'>
            <p>Copyright © 2023 TCOM Corporation. All rights reserved.</p>
          </div>
          {/* end copyright */}
        </div>

        {/* end footer */}
      </div>
    </>
  );
}
export default Layout;
